<template>
  <v-card>
    <v-card-title>
      Редактирование карточки ГКУ центр занятости населения г.Нижнего Новгорода
      <a class="close" @click="$emit('close')">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="#0033A0"
          />
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h4>Название</h4>
            <v-text-field
              v-model="cznData.name"
              filled
              dense
              rounded
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>Участие в Федеральном проекте</h4>
            <v-select
              v-model="cznData.fedProjectYear"
              filled
              dense
              rounded
              :items="yearsWithNull"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>Адрес</h4>
            <v-text-field
              v-model="cznData.address"
              filled
              dense
              rounded
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>Площадь помещения</h4>
            <v-text-field
              filled
              dense
              rounded
              v-model="cznData.area"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>Дата открытия</h4>
            <DatePicker
              :input="cznData.openDate"
              :outputSetter="
                (v) => {
                  cznData.openDate = v;
                }
              "
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>Ответственный от региона за ФП</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>ФИО</h4>
            <v-text-field
              filled
              dense
              rounded
              v-model="cznData.chiefFio"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>Должность</h4>
            <v-text-field
              filled
              dense
              rounded
              v-model="cznData.chiefPost"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>Номер телефона</h4>
            <v-text-field
              filled
              dense
              rounded
              v-model="cznData.chiefPhone"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>Электронная почта</h4>
            <v-text-field
              filled
              dense
              rounded
              v-model="cznData.chiefEmail"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12">
            <h2>Фотографии</h2>
          </v-col>
        </v-row>

        <div class="photos-list"> -->
          <!-- <div class="photos-list-item">
            <img src="~@/assets/1.png" alt="" />
            <v-btn class="round-btn">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.66634 1.27325L8.72634 0.333252L4.99967 4.05992L1.27301 0.333252L0.333008 1.27325L4.05967 4.99992L0.333008 8.72659L1.27301 9.66659L4.99967 5.93992L8.72634 9.66659L9.66634 8.72659L5.93967 4.99992L9.66634 1.27325Z"
                  fill="#0033A0"
                />
              </svg>
            </v-btn>
          </div>
          <div class="photos-list-item">
            <img src="~@/assets/2.png" alt="" />
            <v-btn class="round-btn">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.66634 1.27325L8.72634 0.333252L4.99967 4.05992L1.27301 0.333252L0.333008 1.27325L4.05967 4.99992L0.333008 8.72659L1.27301 9.66659L4.99967 5.93992L8.72634 9.66659L9.66634 8.72659L5.93967 4.99992L9.66634 1.27325Z"
                  fill="#0033A0"
                />
              </svg>
            </v-btn>
          </div>
          <div class="photos-list-item">
            <img src="~@/assets/3.png" alt="" />
            <v-btn class="round-btn">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.66634 1.27325L8.72634 0.333252L4.99967 4.05992L1.27301 0.333252L0.333008 1.27325L4.05967 4.99992L0.333008 8.72659L1.27301 9.66659L4.99967 5.93992L8.72634 9.66659L9.66634 8.72659L5.93967 4.99992L9.66634 1.27325Z"
                  fill="#0033A0"
                />
              </svg>
            </v-btn>
          </div> -->
        <!-- </div>

        <v-btn block outlined small class="add-photo"
          >Добавить фотографии</v-btn
        > -->

        <v-row>
          <v-col cols="12">
            <h2>Перечень ЖС и год внедрения</h2>
          </v-col>
        </v-row>

        <template v-for="(lsituation, lstIndex) in cznData.lsituations">
          <v-row :key="'sdf' + lstIndex">
            <v-col cols="12">
              <v-textarea
                filled
                dense
                rounded
                v-model="lsituation.name"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row :key="'sdfer' + lstIndex">
            <v-col cols="12">
              <v-select
                placeholder=""
                filled
                dense
                rounded
                v-model="lsituation.year"
                :items="years"
              ></v-select>
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col cols="12">
            <a class="add-link" @click="addLs()">+ добавить ЖС</a>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>Перечень БС и год внедрения</h2>
          </v-col>
        </v-row>
        <template v-for="(bsituation, bstIndex) in cznData.bsituations">
          <v-row :key="'bgfifg' + bstIndex">
            <v-col cols="12">
              <v-textarea
                filled
                dense
                rounded
                v-model="bsituation.name"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row :key="'oglfgg;' + bstIndex">
            <v-col cols="12">
              <v-select
                placeholder=""
                filled
                dense
                rounded
                v-model="bsituation.year"
                :items="years"
              ></v-select>
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col cols="12">
            <a class="add-link" @click="addBs()">+ добавить БС</a>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>
              Перечень новых сервисов для граждан и работодателей и год
              внедрения
            </h2>
          </v-col>
        </v-row>

        <template v-for="(service, serviceIndex) in cznData.services">
          <v-row :key="'sdfisdfisfs' + serviceIndex">
            <v-col cols="12">
              <v-textarea
                filled
                dense
                rounded
                v-model="service.name"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row :key="'sdfsdfrtetxn' + serviceIndex">
            <v-col cols="12">
              <v-select
                placeholder=""
                filled
                dense
                rounded
                v-model="service.year"
                :items="years"
              ></v-select>
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col cols="12">
            <a class="add-link" @click="addService()">+ добавить сервис</a>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>Участие в апробациях</h2>
          </v-col>
        </v-row>

        <v-row
          v-for="(aprob, aprobIndex) in cznData.aprobs"
          :key="'fdsgiur' + aprobIndex"
        >
          <v-col cols="12">
            <v-textarea
              filled
              dense
              rounded
              :value="aprob"
              @change="setVal($event, cznData.aprobs, aprobIndex)"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <a class="add-link" @click="cznData.aprobs.push('')">+ добавить</a>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>Внедрение технологии бережливого производства</h2>
          </v-col>
        </v-row>

        <v-row
          v-for="(tech, techIndex) in cznData.techs"
          :key="'igfogf' + techIndex"
        >
          <v-col cols="12">
            <v-textarea
              filled
              dense
              rounded
              :value="tech"
              @change="setVal($event, cznData.techs, techIndex)"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <a class="add-link" @click="cznData.techs.push('')">+ добавить</a>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="submit()"> Сохранить </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DatePicker from "../DatePicker";
import api from "../../modules/api";

export default {
  name: "CznEditDialog",
  components: { DatePicker },
  props: ["region", "cznDataProp"],
  data() {
    return {
      participationOptions: [
        { value: "true", text: "Да" },
        { value: "false", text: "Нет" },
      ],
      years: [2018, 2019, 2020, 2021],
      yearsWithNull: ["", 2018, 2019, 2020, 2021],
      cznData: null,
    };
  },
  methods: {
    async submit() {
      if(this.cznData.name == null || this.cznData.name == ""){
        alert("Название ЦЗН не должно быть пустым")
        return
      }
        
      this.removeEmptyValsFromCznData();
      console.log(this.cznData);
      console.log(this.cznData.area)
      this.cznData.area = (""+this.cznData.area).replace(",", ".");
      
      let req = await api.postJson("/map/saveCzn", this.cznData);
      if (req.ok) {
        this.$router.push("/czn/"+req.payload)
      } else {
        alert("Ошибка сохранения данных");
      }
      this.$emit("close");
    },

    addLs() {
      this.cznData.lsituations.push({
        name: "",
        year: "",
      });
      this.$forceUpdate();
    },

    addBs() {
      this.cznData.bsituations.push({
        name: "",
        year: "",
      });
      this.$forceUpdate();
    },

    addService() {
      this.cznData.services.push({
        name: "",
        year: "",
      });
      this.$forceUpdate();
    },

    clearByName(arr) {
      let res = [];
      for (let s of arr) {
        if (s.name != null && s.name !== "") res.push(s);
      }
      return res;
    },

    setVal(val, arr, index) {
      arr[index] = val;
    },

    clearCznData() {
      this.cznData = {
        name: "",
        region: null,
        fedProjectYear: null,
        address: "",
        area: null,
        openDate: null,
        chiefFio: "",
        chiefPost: "",
        chiefPhone: "",
        chiefEmail: "",
        lsituations: [],
        bsituations: [],
        services: [],
        aprobs: [],
        techs: [],
      };
    },

    removeEmptyVals(arr) {
      let res = [];
      for (let el of arr) {
        if (el != null && el.length !== 0) res.push(el);
      }
      return res;
    },

    removeEmptyValsFromCznData() {
      this.cznData.lsituations = this.clearByName(this.cznData.lsituations);
      this.cznData.bsituations = this.clearByName(this.cznData.bsituations);
      this.cznData.services = this.clearByName(this.cznData.services);
      this.cznData.aprobs = this.removeEmptyVals(this.cznData.aprobs);
      this.cznData.techs = this.removeEmptyVals(this.cznData.techs);
    },
  },
  beforeMount() {
    if (this.cznDataProp != null) {
      this.cznData = this.cznDataProp;
    } else {
      this.clearCznData();
    }
    this.cznData.region = this.region;
  },
};
</script>
